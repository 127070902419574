@tailwind base;
@tailwind components;
@tailwind utilities;

*::-webkit-scrollbar {
  width: 4px;
}
*::-webkit-scrollbar-track {
}
*::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 20px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input {
  outline: none;
}

* {
  margin: 0;
  box-sizing: border-box;
}
