#tv_chart_container {
  height: 100%;
  width: 100%;
  margin-top: 3px;
}

#tv_chart_container iframe {
  min-height: 550px !important;
}

@media screen and (min-width: 575px) {
  #tv_chart_container iframe {
    border-radius: 10px;
    min-height: 350px !important;
    padding: 2px 5px;
  }
}
@media screen and (min-width: 1000px) {
  #tv_chart_container iframe {
    min-height: 470px !important;
  }
}

@media screen and (min-width: 1450px) {
  #tv_chart_container iframe {
    min-height: 780px !important;
  }
}

@media screen and (max-width: 600px) {
  #tv_chart_container {
    border-radius: 16px !important;
  }
}
